import { ReactNode } from 'react'

interface ITabButton {
  content: ReactNode
  href: string
  active?: boolean
  disabled?: boolean
}

export default function TabButton(props: ITabButton) {
  return (
    <li>
      <a
        href={props.href}
        className={`block py-2 pl-3 pr-4 rounded-lg text-3xl md:p-0 font-serif uppercase ${
          props.active
            ? 'text-white bg-orange-400 md:bg-transparent md:text-orange-400'
            : 'text-gray-900 hover:bg-gray-100 dark:hover:bg-orange-200 dark:hover:text-stone-950 md:hover:bg-transparent md:border-0 md:hover:text-orange-300 dark:text-white'
        }`}
        aria-current={props.active ? 'page' : undefined}
      >
        {props.content}
      </a>
    </li>
  )
}
