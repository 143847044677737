import Footer from '../components/footer'
import Navbar from '../components/navbar'
import Container from '../library/container'
import Header1 from '../library/header1'

export default function AboutUs() {
  return (
    <>
      <Navbar activeTab='about-us' />

      <Container className='space-y-6'>
        <Header1>О центре</Header1>
        <div className='text-xl text-gray-700 leading-8 dark:text-gray-300'>
          Дошкольный Вальдорфский Центр ведет обучение воспитателей для вальдорфских детских садов по учебной программе
          &laquo;Основы и методы вальдорфской дошкольной педагогики&raquo;.
          <br />
          <br />
          Центр организует открытые лекции для всех желающих. Проводит ознакомительные семинары по вальдорфской
          педагогике для начинающих воспитателей вальдорфских детских садов, педагогов и родителей, желающих
          познакомиться с подходами вальдорфской педагогики к развитию ребёнка.
          <br />
          <br />
          Обучение воспитателей началось в 1991 году. В 1993 году состоялся первый выпуск - 42 человека стали
          вальдорфскими воспитателями.
          <br />
          <br />
          С этого времени центр ежегодно набирает новых студентов.
          <br />
          <br />
          В центре учатся и москвичи, и студенты из других регионов России. Выпускники Центра основали вальдорфские
          детские сады в Москве, Санкт-Петербурге, Смоленске, Самаре, Чебоксарах, Иваново, Калуге, Ярославле, Рязани,
          Владимире, Нижнем Новгороде, Казани, Ижевске, Волжске, Набережных Челнах, Томске, Воронеже, Ухте, Уфе,
          Красноярске, Вятке, Орске, Иркутске, Находке, Ростове-на-Дону и других городах России.
          <br />
          <br />
          В числе выпускников были представители Украины, Литвы, Армении, открывшие вальдорфские детские сады в Одессе,
          Киеве, Вильнюсе, Ереване.
          <br />
          <br />
          Вальдорфская педагогика видит каждого ребенка как индивидуальное существо со своей судьбой и задачей в этом
          мире. Ребенок нуждается в нашем сопровождении, в нашей любви и заботе. Задача вальдорфского детского сада -
          помочь в этом родителям.
          <br />
          <br />
          Целью воспитания и обучения в вальдорфской педагогике является формирование здоровой телесной и душевной
          основы для свободного раскрытия индивидуальности ребенка.
          <br />
          <br />
          Вся работа в вальдорфском детском саду строится на основе гармоничного примера и свободного детского
          подражания. Здоровый ритм и повторение создают в детях уверенность и радость.
          <br />
          <br />
          В этом возрасте детям нужно много играть, и в вальдорфском детском саду есть для этого все условия.
          <br />
          <br />
          Родители являются активными участниками детсадовской жизни.
        </div>

        <Header1 className='pt-10'>О педагогике</Header1>
        <div className='text-xl text-gray-700 leading-8 dark:text-gray-300'>
          Вальдорфская педагогика возникла как часть духовной науки – антропософии, основанной Рудольфом Штайнером
          (1861-1925).
          <br />
          Антропософия рассматривает мир и человека, в их материальном и духовном, единообразно, используя научный метод
          познания. Так, в результате деятельности Р. Штайнера и его соратников, появляется не только духовная наука как
          таковая, но и многочисленные духовно-практические инициативы:
          <ul className='list-disc list-inside'>
            <li>Вальдорфская педагогика</li>
            <li>Антропософская медицина</li>
            <li>Биодинамическое экологическое сельское хозяйство</li>
            <li>Формы социального самоуправления</li>
            <li>Новые стили в архитектуре, живописи, музыке, поэзии, театре, искусстве движения</li>
          </ul>
          <br />
          <br />
          Вальдорфская педагогика родилась в 1919 году, когда в местечке Вальдорф (Германия) начала работать первая
          школа. Так появилось и наиболее распространенное наименование педагогики – по месту рождения; другое
          распространенное название: педагогика Штайнера. В 1925 году в Штутгарте Элизабет фон Грюнелиус открыла первый
          вальдорфский детский сад. В двадцатых годах ХХ в. в Европе появились лечебно-педагогические учреждения для
          детей с особенностями. На сегодняшний день в мире на всех континентах работают примерно 900 вальдорфских школ,
          2000 вальдорфских детских садов, 500 лечебно-педагогических учреждений. В России вальдорфская педагогика
          развивается с конца 80-х годов ХХ века. В 1989 году открылся первый вальдорфский детский сад. В 1992 году –
          первые вальдорфские школы. На сегодня в России 70 вальдорфских групп и инициатив и 25 школ.
          <br />
          <br />
          Вальдорфская педагогика содержит ряд принципов:
          <ul className='list-disc list-inside'>
            <li>Все дети имеют право на воспитание и обучение; каждый</li>
            <li>Ребенок является личностью</li>
            <li>Воспитание и обучение строятся сообразно возрасту и индивидуальным особенностям детей</li>
            <li>
              Воспитание дошкольников опирается на здоровый пример и свободное детское подражание, обучение в младшей
              школе – на авторитет учителя, обучение в старшей школе вырабатывает личностную самостоятельность
            </li>
            <li>
              Воспитание и обучение создают здоровую телесную и душевную основу для развития личности в течение всей
              жизни
            </li>
          </ul>
        </div>
      </Container>

      <Footer />
    </>
  )
}
