import Email from '../components/email'
import Footer from '../components/footer'
import Navbar from '../components/navbar'
import Phone from '../components/phone'
import Container from '../library/container'
import Header1 from '../library/header1'

export default function Contacts() {
  return (
    <>
      <Navbar activeTab='contacts' />

      <Container className='space-y-6'>
        <Header1>Контакты</Header1>
        <div className='max-w-screen-md flex flex-col space-y-2'>
          <a className='text-orange-300' href='https://yandex.ru/maps/-/CCUsJ6snWD'>
            119146 Москва, М.Фрунзенская Комсомольский пр-т д. 38/16
          </a>

          <div>
            Тел: <Phone />
          </div>

          <div>
            E-mail: <Email />
          </div>

          <a className='text-orange-300' href='https://vk.com/waldorfcentr'>
            Группа в <span className='font-serif font-bold text-blue-500 text-xl'>VK</span>
          </a>
        </div>
      </Container>

      <Footer />
    </>
  )
}
