import dayjs from 'dayjs'
import locale_ru from 'dayjs/locale/ru'

dayjs.locale(locale_ru)

export default function Footer() {
  return (
    <footer className='bg-white dark:bg-stone-950'>
      <div className='w-full max-w-screen-xl mx-auto p-4 md:py-8'>
        <hr className='my-6 border-stone-200 dark:border-stone-700 sm:mx-auto lg:my-8' />
        <span className='block text-sm text-gray-500 sm:text-center'>© {dayjs().format('YYYY')} by Waldorf Center</span>
      </div>
    </footer>
  )
}
