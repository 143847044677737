export type AuthorId = Author['id']
export type Author = (typeof authors)[number]

export const authors = [
  {
    id: 'empty',
    firstName: '',
    lastName: '',
    imageSrc: null,
    description: '',
  },
  {
    id: 'k.babich',
    firstName: 'Кира',
    lastName: 'Бабич',
    imageSrc: '/images/k.babich.webp',
    description:
      'Преподаватель Дошкольного Вальдорфского центра, вальдорфский воспитатель с опытом работы более 20 лет',
  },
  {
    id: 'g.gunter',
    firstName: 'Гунтер',
    lastName: 'Гербхард',
    imageSrc: '/images/g.gunter.webp',
    description: 'Доктор естественных наук из Германии. Преподаватель вальдорфских семинаров в России и за рубежом',
  },
  {
    id: 'e.gramotkina',
    firstName: 'Елена',
    lastName: 'Грамоткина',
    imageSrc: null,
    description: 'Основатель и руководитель Дошкольного Вальдорфского центра',
  },
  {
    id: 'e.volinceva',
    firstName: 'Елена',
    lastName: 'Волынцева',
    imageSrc: '/images/e.volinceva.webp',
    description: 'Врач-педиатр, врач-терапевт, врач-гомеопат Медицинского центра «Терапевтикум»',
  },
  {
    id: 'v.pegov',
    firstName: 'Владимир',
    lastName: 'Пегов',
    imageSrc: '/images/v.pegov.webp',
    description:
      'Доцент, кандидат педагогических наук, профессор кафедры педагогики и психологии, заведующий аспирантурой Смоленской государственной академии физической культуры, спорта и туризма, ведущий преподаватель Периодического семинара для вальдорфских учителей, учитель истории и географии старших классов Смоленской вальдорфской школы «Созвучие»',
  },
  {
    id: 'n.ovchinnikova',
    firstName: 'Наталья',
    lastName: 'Овчинникова',
    imageSrc: null,
    description: '',
  },
  {
    id: 'i.katkova',
    firstName: 'Ирина',
    lastName: 'Каткова',
    imageSrc: null,
    description:
      'Инструктор лечебной физкультуры, более 10 лет опыт работы массажистом, 20-летний педагогический стаж и 12-летний опыт работы в вальдорфский школе.Участница и преподаватель теоретических и практических семинаров и конференций по антропософской медицине, педагогике, психологии, работе с биографией(Москва, Смоленск).',
  },
] as const
