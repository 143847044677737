import dayjs from 'dayjs'
import EventCycleItem from '../components/event-cycle-item'
import Footer from '../components/footer'
import Navbar from '../components/navbar'
import { EventCycle, eventCycles, eventEndTime, eventStartTime } from '../data/event-cycles'
import Container from '../library/container'
import Header1 from '../library/header1'
import Header2 from '../library/header2'

function EventList(props: { events: EventCycle[] }) {
  return (
    <>
      {props.events
        .sort((event) => eventStartTime(event).valueOf())
        .reverse()
        .map((event) => (
          <EventCycleItem event={event} />
        ))}
    </>
  )
}

export default function Events() {
  const currentEvents = eventCycles
    .filter((event) => event.status !== 'canceled' && eventEndTime(event).isAfter(dayjs().subtract(1, 'day'), 'days'))
    .sort((a, b) => eventStartTime(a).diff(eventStartTime(b)))

  const pastEvents = eventCycles
    .filter((event) => event.status === 'canceled' || eventEndTime(event).isBefore(dayjs(), 'days'))
    .sort((a, b) => eventStartTime(b).diff(eventStartTime(a)))

  return (
    <>
      <Navbar activeTab='events' />

      <Container className='space-y-20'>
        {currentEvents.length ? (
          <div className='space-y-2 w-full'>
            <Header1 className='self-start'>События</Header1>
            <EventList events={currentEvents} />
          </div>
        ) : null}

        {pastEvents.length ? (
          <div className='space-y-2 w-full'>
            <Header2 className='self-start'>Прошлые события</Header2>
            <EventList events={pastEvents} />
          </div>
        ) : null}
      </Container>

      <Footer />
    </>
  )
}
