import Footer from '../components/footer'
import Navbar from '../components/navbar'

export default function Page404() {
  return (
    <>
      <Navbar />

      <div className='flex bg-white dark:bg-stone-950 h-full'>
        <div className='flex flex-col w-full items-center px-10'>
          <div className='absolute blur-xl top-1/3 left-1/2 w-64 h-64 rounded-s-full bg-orange-600/30'></div>
          <div className='absolute blur-xl top-1/2 left-1/2 w-48 h-48 rounded-s-full bg-orange-200/30'></div>
          <div className='absolute blur-2xl top-1/6 left-1/4 w-64 h-64 rounded-s-full bg-orange-400/30'></div>

          <div className='text-orange-400 font-bold text-[360px] font-serif'>
            4<span className='text-orange-200'>0</span>4
          </div>

          <div className='flex flex-col items-center space-y-4'>
            <div className='text-3xl dark:text-stone-100'>Эта страница не найдена</div>
            <a href='/#' className='text-xl text-orange-400 font-bold underline underline-offset-4'>
              Вернутся на главную
            </a>
          </div>
        </div>
      </div>

      <Footer />
    </>
  )
}
