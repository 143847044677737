import dayjs, { Dayjs } from 'dayjs'
import locale_ru from 'dayjs/locale/ru'
import { Author } from '../data/authors'

dayjs.locale(locale_ru)

interface IEventItem {
  startTime: Dayjs
  duration: number
  title: string
  author: Author
}

export default function EventItem(props: IEventItem) {
  const startTime = dayjs(props.startTime)
  const endTime = startTime.add(props.duration, 'minutes')
  const active = !endTime.isValid() || endTime.isAfter(dayjs())

  return (
    <div className='relative'>
      <div className='group font-sans pb-4 border-b-4 border-gray-400 dark:border-gray-600 hover:border-orange-200'>
        {/* pc version */}
        <div className='hidden md:flex font-weight-900 align-middle space-x-4'>
          {startTime.isValid() && (
            <>
              <div className='transition font-mono text-6xl text-gray-400 dark:text-gray-600 pt-1 uppercase group-hover:text-orange-200'>
                {startTime.format('dd')}
              </div>
              <div className='flex flex-col'>
                <div className='transition whitespace-nowrap text-4xl text-gray-600 dark:text-gray-400 group-hover:text-orange-400 group-hover:-translate-y-1'>
                  {startTime.format('D MMM')}
                </div>
                <div className='flex space-x-1'>
                  <div className='transition text-2xl text-gray-600 dark:text-gray-400 group-hover:text-orange-400 uppercase group-hover:-translate-y-1'>
                    {startTime.format('H:mm')}
                  </div>
                  <div className='transition text-2xl text-gray-400 dark:text-gray-600 group-hover:text-orange-200 uppercase'>
                    {endTime.format('H:mm')}
                  </div>
                </div>
              </div>
            </>
          )}

          <div className='flex flex-col pt-2'>
            <div className='transition text-xl text-gray-700 dark:text-gray-600 group-hover:text-orange-200'>
              {props.author.firstName} {props.author.lastName}
            </div>
            <div className='transition text-2xl group-hover:text-orange-400 group-hover:-translate-y-1'>
              {props.title}
            </div>
          </div>
        </div>

        {/* mobile version */}
        <div className='md:hidden flex flex-col'>
          {startTime.isValid() && (
            <div className='flex font-weight-900 align-middle space-x-4'>
              <div className='flex space-x-2'>
                <div className='transition text-2xl text-gray-400 dark:text-gray-600 uppercase group-hover:text-orange-200'>
                  {startTime.format('dd')}
                </div>

                <div className='transition whitespace-nowrap text-2xl text-gray-600 dark:text-gray-400 group-hover:text-orange-400 group-hover:-translate-y-1'>
                  {startTime.format('D MMM')}
                </div>
              </div>

              <div className='flex space-x-1'>
                <div className='transition text-2xl text-gray-600 dark:text-gray-400 group-hover:text-orange-400 group-hover:-translate-y-1'>
                  {startTime.format('H:mm')}
                </div>

                <div className='transition text-2xl text-gray-400 dark:text-gray-600 group-hover:text-orange-200'>
                  {endTime.format('H:mm')}
                </div>
              </div>
            </div>
          )}

          <div className='flex space-x-4'>
            <div className='transition text-xl group-hover:text-orange-400 group-hover:-translate-y-1'>
              {props.title}
              <div className='transition text-lg text-gray-600 dark:text-gray-600 group-hover:text-orange-200'>
                {props.author.firstName} {props.author.lastName}
              </div>
            </div>
          </div>
        </div>
      </div>

      {!active && (
        <div>
          <div className='absolute top-0 h-full w-full bg-white/80 dark:bg-stone-950/80'></div>
          <div className='absolute top-0 h-full w-full flex items-start justify-end'>
            <div className='text-2xl text-gray-600 dark:text-gray-200 lowercase'>Прошло</div>
          </div>
        </div>
      )}
    </div>
  )
}
