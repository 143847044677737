import dayjs from 'dayjs'
import locale_ru from 'dayjs/locale/ru'
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './input.css'
import './fonts/AmaticSC-Regular.ttf'
import './fonts/AmaticSC-Bold.ttf'

dayjs.locale(locale_ru)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
