import { PropsWithChildren } from 'react'

interface IContainer extends PropsWithChildren {
  className?: string
}

export default function Container(props: IContainer) {
  return (
    <div className='flex flex-col px-10 md:px-20 py-10 items-center dark:bg-stone-950 dark:text-white'>
      <div className={`max-w-screen-md flex flex-col items-center ${props.className}`}>{props.children}</div>
    </div>
  )
}
