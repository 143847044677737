import { CostInfo } from '../data/event-cycles'

interface ICost {
  costInfo: CostInfo
}

export default function Cost(props: ICost) {
  return (
    <div className='flex flex-col text-center border px-4 py-2 rounded-lg'>
      <div>{props.costInfo.description}</div>
      <div className='text-xl font-bold'>{props.costInfo.cost} рублей</div>
    </div>
  )
}
