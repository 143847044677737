import dayjs from 'dayjs'
import { useParams } from 'react-router-dom'
import EventPage from '../components/Events/event-page'
import Footer from '../components/footer'
import Navbar from '../components/navbar'
import { eventCycles, eventEndTime } from '../data/event-cycles'
import Header1 from '../library/header1'

export default function Event() {
  const { id } = useParams()
  const currentEvent = eventCycles.find((e) => e.id === +(id ?? ''))

  if (currentEvent === undefined) {
    return null
  }

  const endTime = eventEndTime(currentEvent)
  const active = !endTime.isValid() || endTime.isAfter(dayjs())

  return (
    <>
      <Navbar />

      <div className='z-10 flex flex-col pt-10 px-10 md:px-40 items-center space-y-12'>
        {
          <>
            <Header1>{currentEvent.title}</Header1>
            <div className='text-lg text-left'>{currentEvent.description}</div>
            <EventPage eventCycle={currentEvent} showCost={active} />
          </>
        }
      </div>

      <Footer />
    </>
  )
}
