interface IPhone {
  phoneNumber?: string
}

var DEFAULT_PHONE = '+7 (499) 245-90-49'

export default function Phone(props: IPhone) {
  const phone = props.phoneNumber ?? DEFAULT_PHONE

  return (
    <a className='inline text-orange-300' href={`tel:${phone}`}>
      {phone}
    </a>
  )
}
