import { BrowserRouter, Route, Routes } from 'react-router-dom'
import AboutUs from './pages/AboutUs'
import Contacts from './pages/Contacts'
import Event from './pages/Event'
import Events from './pages/Events'
import Home from './pages/Home'
import Page404 from './pages/Page404'

const AppRoutes = () => (
  <BrowserRouter>
    <Routes>
      <Route path='about-us' element={<AboutUs />} />
      <Route path='events' element={<Events />} />
      <Route path='event/:id' element={<Event />} />
      <Route path='contacts' element={<Contacts />} />
      <Route path='/' element={<Home />} />
      <Route path='*' element={<Page404 />} />
    </Routes>
  </BrowserRouter>
)

export default AppRoutes
