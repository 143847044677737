import dayjs from 'dayjs'
import AlertCard from '../components/alert-card'
import Email from '../components/email'
import EventPage from '../components/Events/event-page'
import Footer from '../components/footer'
import Navbar from '../components/navbar'
import Phone from '../components/phone'
import {
    eventCycles, eventEndTime, eventStartTime, eventStatusComparer
} from '../data/event-cycles'
import Header1 from '../library/header1'

export default function Home() {
  const currentEvents = eventCycles
    .filter((event) => eventEndTime(event).isAfter(dayjs().subtract(1, 'day'), 'days'))
    .sort((a, b) => eventStartTime(a).diff(eventStartTime(b)))
    .sort((a, b) => eventStatusComparer(a.status, b.status))

  return (
    <>
      <Navbar activeTab='main' />

      <img
        className='absolute blur-3xl brightness-150 backdrop-opacity-20 h-auto w-full p-40 opacity-30'
        src='/images/image-1.webp'
        alt='Картинка с детьми'
      />
      <div className='flex justify-center mt-10'>
        <img className='rounded-xl z-10 h-auto w-2/3' src='/images/image-1.webp' alt='Картинка с детьми' />
      </div>

      <div className='z-10 flex flex-col px-10 md:px-20 pt-10 items-center space-y-24'>
        <div className='max-w-screen-md flex flex-col items-center space-y-4'>
          <Header1>Дошкольный Вальдорфский Центр</Header1>
          <div className='text-lg md:text-center z-10'>
            Дошкольный Вальдорфский Центр ведет обучение воспитателей для вальдорфских детских садов по учебной
            программе «Основы и методы вальдорфской дошкольной педагогики». Проводит регулярные курсы повышения
            квалификации педагогов. Ознакомительные семинары и открытые лекции для всех желающих
          </div>
        </div>

        <AlertCard>
          Дошкольный Вальдорфский центр в Москве объявляет набор для будущих студентов на 3-х годичное обучение (2023г.
          - 2026г.) по программе
          <br />
          <b>«Основы и методы дошкольной вальдорфской педагогики»</b>
          <br />
          <br />
          Заявку на обучение можно посылать на <Email />
          <br />
          C 15 мая 2023 года будут проходить личные собеседования. Запись на собеседование по телефону <Phone /> или{' '}
          <Email />
        </AlertCard>

        <div className='space-y-40'>
          {currentEvents.map((currentEvent) => (
            <div className='flex flex-col items-center space-y-8'>
              <Header1>{currentEvent.title}</Header1>

              <div className='text-lg text-left max-w-screen-md'>
                {currentEvent.body}
                <a href={`event/${currentEvent.id}`} className='relative'>
                  <div className='text-orange-400'>Подробнее</div>
                </a>
              </div>

              <EventPage eventCycle={currentEvent} showCost={true} />
            </div>
          ))}
        </div>
      </div>

      <Footer />
    </>
  )
}
