import dayjs from 'dayjs'
import locale_ru from 'dayjs/locale/ru'
import { EventCycle, eventEndTime, eventStartTime } from '../data/event-cycles'

dayjs.locale(locale_ru)

interface IEventCycleItem {
  event: EventCycle
}

export default function EventCycleItem(props: IEventCycleItem) {
  if (props.event.events.length === 0) {
    return null
  }

  const startTime = eventStartTime(props.event)
  const endTime = eventEndTime(props.event)

  const paste = dayjs().isAfter(endTime, 'days')

  return (
    <a href={`event/${props.event.id}`} className='relative'>
      <div className='font-sans flex flex-col items-center'>
        {/* pc */}
        <div className='group hidden md:flex space-x-6 rounded-3xl text-center py-4 w-full'>
          <div className='flex flex-col text-left space-y-2 w-full'>
            <div className='flex justify-between items-start'>
              <div
                className={`transition text-2xl leading-6 group-hover:text-orange-400 ${paste ? 'text-gray-400' : ''}`}
              >
                {props.event.title}
              </div>
              {props.event.status === 'canceled' ? (
                <div className='group-hover:text-orange-400 text-gray-600 dark:text-gray-400'>Не состоится</div>
              ) : (
                <div className='flex whitespace-nowrap text-right'>
                  <div className='transition text-md text-gray-600 dark:text-gray-400'>
                    {startTime.isSame(endTime, 'date') ? (
                      <span className='group-hover:text-orange-400'>{endTime.format('D MMMM YYYY')}</span>
                    ) : (
                      <>
                        c{' '}
                        <span className='group-hover:text-orange-400'>
                          {startTime.isSame(endTime, 'year')
                            ? startTime.isSame(endTime, 'month')
                              ? startTime.format('D')
                              : startTime.format('D MMMM')
                            : startTime.format('D MMMM YYYY')}
                        </span>{' '}
                        по <span className='group-hover:text-orange-400'>{endTime.format('D MMMM YYYY')}</span>
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>

            <div className='transition text-md text-gray-700 dark:text-gray-600 line-clamp-3 leading-5'>
              {props.event.body}
            </div>
          </div>
        </div>

        {/* mobile */}
        <div className='group md:hidden space-x-6rounded-lg py-2 text-lg md:text-center'>
          <div className='flex flex-col text-left space-y-1'>
            <div className='flex flex-col'>
              <div
                className={`transition font-bold line-clamp-2 text-sm leading-5 group-hover:text-orange-400 ${
                  paste ? 'text-gray-400' : ''
                }`}
              >
                {props.event.title}
              </div>

              {props.event.status === 'canceled' ? (
                <div className='text-sm group-hover:text-orange-400 text-gray-600 dark:text-gray-400'>Не состоится</div>
              ) : (
                <div className='transition text-sm text-gray-600 dark:text-gray-400'>
                  {startTime.isSame(endTime, 'date') ? (
                    <span className='group-hover:text-orange-400'>{endTime.format('D MMMM YYYY')}</span>
                  ) : (
                    <>
                      c{' '}
                      <span className='group-hover:text-orange-400'>
                        {startTime.isSame(endTime, 'year')
                          ? startTime.isSame(endTime, 'month')
                            ? startTime.format('D')
                            : startTime.format('D MMMM')
                          : startTime.format('D MMMM YYYY')}
                      </span>{' '}
                      по <span className='group-hover:text-orange-400'>{endTime.format('D MMMM YYYY')}</span>
                    </>
                  )}
                </div>
              )}
            </div>

            <div className='transition text-sm text-gray-700 dark:text-gray-600 line-clamp-2 leading-5'>
              {props.event.body}
            </div>
          </div>
        </div>
      </div>
    </a>
  )
}
