import dayjs from 'dayjs'
import { authors } from '../../data/authors'
import { EventCycle } from '../../data/event-cycles'
import AlertCard from '../alert-card'
import Cost from '../cost'
import EventItem from '../event-item'

type IEventPage = {
  eventCycle: EventCycle
  showCost: boolean
}

export default function EventPage({ eventCycle, showCost }: IEventPage) {
  return (
    <div className='z-10 max-w-screen-lg flex flex-col items-center space-y-10'>
      {/* <Authors event={event} /> */}

      {eventCycle.alert && <AlertCard>{eventCycle.alert}</AlertCard>}

      {eventCycle.status === 'planned' && (
        <>
          <div className='space-y-6 w-4/5 md:min-w-[520px]'>
            {eventCycle.events.length ? (
              <>
                <h3 className='text-2xl md:text-3xl font-weight-800 text-center text-orange-400'>Расписание событий</h3>

                {eventCycle.events
                  .sort((event) => dayjs(event.startTime).valueOf())
                  .map((event, idx) => {
                    const author = authors.find((a) => a.id === event.author)
                    return author ? (
                      <EventItem
                        key={idx}
                        startTime={dayjs(event.startTime)}
                        duration={event.duration}
                        title={event.title}
                        author={author}
                      />
                    ) : null
                  })}
              </>
            ) : (
              <div>В ближайшее время событий нет</div>
            )}
          </div>

          {showCost && (
            <div className='flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4 justify-center'>
              {eventCycle.costs.map((cost, idx) => (
                <Cost key={idx} costInfo={cost} />
              ))}
            </div>
          )}

          <div className='text-lg self-start'>{eventCycle.footer}</div>
        </>
      )}
    </div>
  )
}
