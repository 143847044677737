import { useState } from 'react'
import TabButton from './tab-button'
import Tabs from './tabs'

interface INavbar {
  activeTab?: 'main' | 'about-us' | 'contacts' | 'events'
}

export default function Navbar(props: INavbar) {
  var [isMenuOpen, setIsMenuOpen] = useState(false)

  return (
    <nav className='bg-white dark:bg-stone-950 border-gray-200 flex flex-wrap items-center justify-between px-4 md:px-8 py-4'>
      <a href='/#' className='flex flex-col items-start'>
        <div className='font-serif font-weight-300 text-2xl md:text-3xl dark:text-white'>
          Дошкольный Вальдорфский центр
        </div>
        <div className='font-serif font-weight-300 text-md md:text-xl text-orange-400'>
          обучение, повышение квалификации, практика
        </div>
      </a>
      <button
        data-collapse-toggle='navbar-default'
        data-toggle='hidden'
        type='button'
        className='inline-flex items-center p-2 ml-3 text-sm text-gray-500 dark:text-white rounded-lg md:hidden hover:bg-gray-100 dark:hover:bg-stone-800 focus:outline-none focus:ring-2 focus:ring-gray-200'
        aria-controls='navbar-default'
        aria-expanded='false'
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        <span className='sr-only'>Открыть меню</span>
        <svg
          className='w-6 h-6'
          aria-hidden='true'
          fill='currentColor'
          viewBox='0 0 20 20'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            fill-rule='evenodd'
            d='M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z'
            clip-rule='evenodd'
          />
        </svg>
      </button>
      <div className={`${isMenuOpen ? 'block' : 'hidden'} w-full md:block md:w-auto`}>
        <Tabs>
          <TabButton href='/' content='Главная' active={props.activeTab === 'main'} />
          <TabButton href='/events' content='События' active={props.activeTab === 'events'} />
          <TabButton href='/about-us' content='Наш центр' active={props.activeTab === 'about-us'} />
          <TabButton href='/contacts' content='Контакты' active={props.activeTab === 'contacts'} />
        </Tabs>
      </div>
    </nav>
  )
}
