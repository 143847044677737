import AppRoutes from './AppRoutes'

function App() {
  return (
    <div className='min-h-screen bg-white dark:bg-stone-950 dark:text-white'>
      <AppRoutes />
    </div>
  )
}

export default App
