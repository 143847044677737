import dayjs from 'dayjs'
import { ReactNode } from 'react'
import Email from '../components/email'
import Phone from '../components/phone'
import Header2 from '../library/header2'
import { AuthorId } from './authors'

type EventStatus = 'planned' | 'canceled'

export const eventStatusComparer: (a: EventStatus, b: EventStatus) => number = (a, b) => {
  if (a === b) return 0
  else if (b === 'canceled') return -1
  else return 1
}

export type EventCycle = {
  id: number
  status: EventStatus
  alert: ReactNode | null
  title: string
  body: string
  description: ReactNode
  footer: ReactNode | null
  events: Event[]
  costs: CostInfo[]
}

export type Event = {
  startTime: string
  duration: number
  title: string
  author: AuthorId
}

export type CostInfo = {
  description: ReactNode
  cost: number
}

export const eventStartTime = (eventCycle: EventCycle) => dayjs(eventCycle.events[0].startTime)
export const eventEndTime = (eventCycle: EventCycle) => {
  const lastEvent = eventCycle.events[eventCycle.events.length - 1]
  return dayjs(lastEvent.startTime).add(lastEvent.duration, 'minutes')
}

const justCostAndDescription: (description: string, cost: number) => CostInfo = (description, cost) => ({
  description: description,
  cost: cost,
})

const justCostAndNodeDescription: (description: ReactNode, cost: number) => CostInfo = (description, cost) => ({
  description: description,
  cost: cost,
})

const titleAndDescription: (title: string, description: string, cost: number) => CostInfo = (
  title,
  description,
  cost
) => ({
  description: (
    <>
      <div className='text-xl font-bold'>{title}</div>
      {description}
    </>
  ),
  cost: cost,
})

const footerFrunzaInfoWithoutPhoneAndEmail = (
  <>
    Место проведения: м. Фрунзенская, Комсомольский пр-т. д. 38/16.
    <br />
    При себе иметь сменную обувь
  </>
)

const footerFrunzaInfo = (
  <>
    Место проведения: м. Фрунзенская, Комсомольский пр-т. д. 38/16.
    <br />
    Запись тел: <Phone /> e-mail: <Email />
    <br />
    При себе иметь сменную обувь
  </>
)

const footerZoomInfo = <>Лекции будут проходить на платформе ZOOM</>

const footerNOvchinnikova = (
  <>
    Запись и вопросы по тел.
    <Phone phoneNumber='+7 (926) 352-34-71' />
  </>
)

const linkAndRequisitesAlert = (url: string) => (
  <div className='text-left'>
    {linkRegistration(url)}
    <br />
    {requisites}
  </div>
)

const linkAndRequisitesAlertAndInfo = (url: string, info: ReactNode) => (
  <div className='text-left'>
    {linkRegistration(url)}
    <br />
    {info}
    <br />
    {requisites}
  </div>
)

const linkAndRequisitesAlertWithQr = (url: string, id: number) => (
  <div className='text-left'>
    {linkRegistration(url)}
    <br />
    {requisites}
    <br />
    {qr(id)}
  </div>
)

const linkRegistration = (url: string) => (
  <div>
    Регистрация на лекции по{' '}
    <a className='text-orange-300' href={url}>
      ссылке
    </a>
  </div>
)

const qr = (id: number) => (
  <>
    Можно оплатить по QR коду
    <br />
    В назначении платежа: образовательные услуги.
    <br />
    <div className='flex justify-center'>
      <img className='h-40 w-40 bg-white' src={`/images/qr/event-${id}.jpg`} alt='QR код для оплаты' />
    </div>
  </>
)

const requisites = (
  <div>
    <b>Реквизиты для оплаты</b>
    <br />
    ЧУДО «Дошкольный Вальдорфский центр»
    <br />
    ИНН получателя 7705466636 КПП 770401001
    <br />
    Счет получателя 40703810500750000017
    <br />
    Банк: Филиал «Центральный» Банка ВТБ (ПАО) г. Москва
    <br />
    Реквизиты банка БИК 044525411, к/с 30101810145250000411 Ф.И.О. плательщика
  </div>
)

export const eventCycles: EventCycle[] = [
  {
    id: 0,
    status: 'planned',
    alert: null,
    title: 'Раннее детство. От 0 до 7 лет',
    body: 'Вальдорфский центр приглашает всех желающих на родительский семинар Раннее детство. От 0 до 7 лет. Как научиться понимать ребенка? Что для него является важным? Как помочь ребенку?',
    description: '',
    footer: footerFrunzaInfo,
    events: [
      {
        startTime: '2022-09-19 18:30:00',
        duration: 120,
        title: 'Семилетние эпохи в развитии ребенка',
        author: 'v.pegov',
      },
      {
        startTime: '2022-09-20 18:30:00',
        duration: 120,
        title: 'Семилетние эпохи в развитии ребенка',
        author: 'v.pegov',
      },
      {
        startTime: '2022-09-26 18:30:00',
        duration: 120,
        title: 'Сказки в жизни ребенка',
        author: 'k.babich',
      },
      {
        startTime: '2022-09-27 18:30:00',
        duration: 120,
        title: 'Сказки в жизни ребенка',
        author: 'k.babich',
      },
      {
        startTime: '2022-10-03 18:30:00',
        duration: 120,
        title: 'Свободная игра',
        author: 'e.gramotkina',
      },
      {
        startTime: '2022-10-04 18:30:00',
        duration: 120,
        title: 'Свободная игра',
        author: 'e.gramotkina',
      },
      {
        startTime: '2022-10-10 18:30:00',
        duration: 120,
        title: 'Детские болезни',
        author: 'e.volinceva',
      },
      {
        startTime: '2022-10-11 18:30:00',
        duration: 120,
        title: 'Здоровое питание',
        author: 'e.volinceva',
      },
      {
        startTime: '2022-10-17 18:30:00',
        duration: 120,
        title: 'Готовность ребенка к школе',
        author: 'k.babich',
      },
      {
        startTime: '2022-10-18 18:30:00',
        duration: 120,
        title: 'Готовность ребенка к школе',
        author: 'k.babich',
      },
    ],
    costs: [
      titleAndDescription('Абонемент', 'При записи до 17 сентября 2022', 6500),
      titleAndDescription('Абонемент', 'При записи после 18 сентября 2022', 7000),
      titleAndDescription('Разово', 'Разовое посещение занятий', 800),
    ],
  },
  {
    id: 1,
    status: 'planned',
    alert: null,
    title: 'Лекции Гунтера Гербхарда',
    body: 'Дорогие воспитатели, учителя и родители! Коллегия Дошкольного Вальдорфского центра приглашает Вас на лекции Гунтера Гербхарда (доктор естественных наук, более 15 лет работал учителем естествознания в вальдорфских школах Германии, преподаватель вальдорфских семинаров в России и за рубежом)',
    description:
      'Эти лекции не будут абстрактным философствованием, но мы попробуем пролить свет на нашу повседневную жизнь конкретным образом, чтобы содержание лекций могло стимулировать индивидуальную человеческую волю',
    footer: footerFrunzaInfo,
    events: [
      {
        startTime: '2023-05-30 18:30:00',
        duration: 90,
        title: 'Что такое гётеанизм и почему Гётеанум находится в Дорнахе?',
        author: 'g.gunter',
      },
      {
        startTime: '2023-06-01 18:30:00',
        duration: 90,
        title: 'Что отличает гётеанизм от антропософской духовной науки?',
        author: 'g.gunter',
      },
      {
        startTime: '2023-06-06 18:30:00',
        duration: 90,
        title: 'Значение антропософии для нашего настоящего',
        author: 'g.gunter',
      },
      {
        startTime: '2023-06-08 18:30:00',
        duration: 90,
        title: 'Антропософия и вальдорфская педагогика',
        author: 'g.gunter',
      },
    ],
    costs: [
      justCostAndDescription('Стоимость одной лекции', 500),
      justCostAndNodeDescription(
        <>
          Стоимость <b>4-х</b> лекций
        </>,
        1800
      ),
    ],
  },
  {
    id: 2,
    status: 'planned',
    alert: null,
    title: 'Лекции Гунтера Гербхарда',
    body: 'Дорогие воспитатели, учителя и родители! Коллегия Дошкольного Вальдорфского центра приглашает Вас на лекции Гунтера Гербхарда (доктор естественных наук, более 15 лет работал учителем естествознания в вальдорфских школах Германии, преподаватель вальдорфских семинаров в России и за рубежом)',
    description:
      'Понятие философского камня известно из средневековой алхимии. Но что это был за философский камень? Является ли это понятие тем, что сегодня нас, "просвещённых людей", уже не касается? Или же оно, правильно понятое, имеет огромное значение в социуме и в педагогике?',
    footer: footerFrunzaInfo,
    events: [
      {
        startTime: '2023-09-12 18:30:00',
        duration: 90,
        title: 'Какое отношение имеет "философский камень" к вопросам современности?',
        author: 'g.gunter',
      },
      {
        startTime: '2023-09-14 18:30:00',
        duration: 90,
        title: 'Продолжение',
        author: 'g.gunter',
      },
    ],
    costs: [justCostAndDescription('Стоимость за две лекции', 1000)],
  },
  {
    id: 3,
    status: 'canceled',
    alert: (
      <>
        <Header2>Родительский семинар "Раннее детство. От 0 до 7 лет" отменен</Header2>
        Если у вас есть вопросы напишите нам на почту <Email /> или позвоните по телефону <Phone />
      </>
    ),
    title: 'Раннее детство. От 0 до 7 лет',
    body: 'Вальдорфский центр приглашает всех желающих на родительский семинар «Раннее детство. От 0 до 7 лет»',
    description: 'Как научиться понимать ребенка? Что для него является важным? Как помочь ребенку?',
    footer: footerFrunzaInfo,
    events: [
      {
        startTime: '2023-09-18 18:30:00',
        duration: 120,
        title: 'Семилетние эпохи в развитии ребенка',
        author: 'v.pegov',
      },
      {
        startTime: '2023-09-19 18:30:00',
        duration: 120,
        title: 'Семилетние эпохи в развитии ребенка',
        author: 'v.pegov',
      },
      {
        startTime: '2023-09-25 18:30:00',
        duration: 120,
        title: 'Сказки в жизни ребенка',
        author: 'k.babich',
      },
      {
        startTime: '2023-09-26 18:30:00',
        duration: 120,
        title: 'Сказки в жизни ребенка',
        author: 'k.babich',
      },
      {
        startTime: '2023-10-02 18:30:00',
        duration: 120,
        title: 'Детские болезни',
        author: 'e.volinceva',
      },
      {
        startTime: '2023-10-03 18:30:00',
        duration: 120,
        title: 'Здоровое питание',
        author: 'e.volinceva',
      },
      {
        startTime: '2023-10-09 18:30:00',
        duration: 120,
        title: 'Свободная игра',
        author: 'e.gramotkina',
      },
      {
        startTime: '2023-10-10 18:30:00',
        duration: 120,
        title: 'Свободная игра',
        author: 'e.gramotkina',
      },
      {
        startTime: '2023-10-16 18:30:00',
        duration: 120,
        title: 'Готовность ребенка к школе',
        author: 'k.babich',
      },
      {
        startTime: '2023-10-18 18:30:00',
        duration: 120,
        title: 'Готовность ребенка к школе',
        author: 'k.babich',
      },
    ],
    costs: [
      titleAndDescription('Абонемент', 'При записи до 17 сентября 2023', 6500),
      titleAndDescription('Абонемент', 'При записи после 18 сентября 2023', 7000),
      titleAndDescription('Разово', 'Разовое посещение занятий', 800),
    ],
  },
  {
    id: 4,
    status: 'planned',
    alert: null,
    title: 'Лекции Владимира Пегова',
    body: 'Дошкольный Вальдорфский центр приглашает Родителей, Педагогов и всех желающих на лекции Владимира Пегова',
    description: (
      <>
        <b>Искусство правильного утомления, или как развлекательность убивает образование</b>
        <br />
        <br />
        Цивилизация потребительства агрессивно навязывает представление о человеке-потребителе. Соответственно,
        повсеместно внедряемая англо-американская модель образования формирует потребительство, как главную
        «способность» у детей. Потребительство, в свою очередь, предполагает стремление к постоянному комфорту. Отсюда
        «развлекательное образование» становится ведущим трендом во всём мире. Важно понять, что на самом деле
        развивается при этом у детей.
        <br />
        <br />
        <br />
        <b>
          Вальдорфская педагогика и мифы в образовании: позитивное подкрепление («педагогика похвалы и радости»), «после
          трёх уже поздно» (школы раннего развития), «ваш ребёнок гений» и др.
        </b>
        <br />
        <br />
        Попытки осуществить действительно современное образование, одновременно пробуждающее необходимые человеческие
        качества и способности, и при этом оздоравливающее детей, натыкаются на постоянно создаваемые педагогические
        мифы. Значимую роль в этом играют нейронауки. Для педагогов и родителей становится актуальной задачей
        распознавание этих мифов и тех последствий, к которым они приводят в воспитании детей.
        <br />
        <br />
        <br />
        <b>Безграничные дети и проблемы свободного воспитания</b>
        <br />
        <br />
        Проблема границ у современных детей и подростков. Почему у нынешних детей возникают трудности с правильным
        переживанием границ: телесных и социальных? Каким образом подросткам разрешить вопрос соотнесённости
        ограниченности и свободы?
        <br />
        <br />
        <br />
        <b>Отцифрованные дети, или как атакуется детство и наши человеческие качества (2 лекции)</b>
        <br />
        <br />
        Недавно проведённое исследование убедительно показало, что чем раньше дети получают доступ к смартфону, тем чаще
        они сталкиваются с проблемами с психикой во взрослом возрасте. К таким выводам пришли нейробиологи из
        американской некоммерческой организации Sapien Labs в ходе исследования с участием почти 28 тысяч людей 18-24
        лет. Это первое поколение, у которого гаджеты могли быть с рождения. Но проблема находится не только в душевной
        сфере, но и в телесной, и в духовной.
      </>
    ),
    footer: footerFrunzaInfo,
    events: [
      {
        startTime: '2023-09-20 18:30:00',
        duration: 90,
        title: 'Искусство правильного утомления, или как развлекательность убивает образование',
        author: 'v.pegov',
      },
      {
        startTime: '2023-09-21 18:30:00',
        duration: 90,
        title:
          'Вальдорфская педагогика и мифы в образовании: позитивное подкрепление, после трёх уже поздно, ваш ребёнок гений и др.',
        author: 'v.pegov',
      },
      {
        startTime: '2023-09-22 18:30:00',
        duration: 90,
        title: 'Безграничные дети и проблемы свободного воспитания',
        author: 'v.pegov',
      },

      {
        startTime: '2023-09-23 9:30:00',
        duration: 75,
        title: 'Отцифрованные дети, или как атакуется детство и наши человеческие качества. Лекция первая',
        author: 'v.pegov',
      },
      {
        startTime: '2023-09-23 11:00:00',
        duration: 75,
        title: 'Отцифрованные дети, или как атакуется детство и наши человеческие качества. Лекция вторая',
        author: 'v.pegov',
      },
    ],
    costs: [
      justCostAndDescription('Стоимость каждой отдельной лекции', 500),
      justCostAndDescription('Стоимость двух последних лекций (23.09.23)', 1000),
    ],
  },
  {
    id: 5,
    status: 'planned',
    alert: null,
    title: 'Лекции Гунтера Гербхарда',
    body: 'Дорогие воспитатели, учителя и родители! Коллегия Дошкольного Вальдорфского центра приглашает Вас на лекции Гунтера Гербхарда (доктор естественных наук, более 15 лет работал учителем естествознания в вальдорфских школах Германии, преподаватель вальдорфских семинаров в России и за рубежом)',
    description:
      'Эти лекции не будут абстрактным философствованием, но мы попробуем пролить свет на нашу повседневную жизнь конкретным образом, чтобы содержание лекций могло стимулировать индивидуальную человеческую волю',
    footer: footerFrunzaInfo,
    events: [
      {
        startTime: '2023-10-17 18:30:00',
        duration: 90,
        title: 'Михаэлическое мышление и вопросы нашего времени',
        author: 'g.gunter',
      },
      {
        startTime: '2023-10-19 18:30:00',
        duration: 90,
        title: 'Михаэлическое мышление и вопросы нашего времени',
        author: 'g.gunter',
      },
    ],
    costs: [justCostAndDescription('Стоимость двух лекций', 1000)],
  },
  {
    id: 6,
    status: 'planned',
    alert: linkAndRequisitesAlert(
      'https://docs.google.com/forms/d/e/1FAIpQLScUIBJuANsoBmEnBipvTt1D2C2eXCq2iQlVNI9pQe73QaslOA/viewform?usp=share_link'
    ),
    title: 'Лекции Владимира Пегова',
    body: 'Дошкольный Вальдорфский центр приглашает Родителей, Педагогов и всех желающих на лекции Владимира Пегова',
    description: (
      <>
        Дошкольный вальдорфский центр приглашает педагогов, родителей и всех желающих на цикл онлайн лекций В. А. Пегова
        (ведущий преподаватель Периодического семинара, Дошкольного Вальдорфского центра. Кандидат педагогических наук,
        доцент. Доцент кафедры педагогики и психологии, основатель и учитель Смоленской вальдорфской школы).
        <br />
        «Современное воспитание в первые три семилетия: Педагогика достойная ребёнка» (цикл из шести лекций)
        <br />
        <br />
        <div className='text-sm'>Лекция 1 и 2</div>
        <b className='text-xl'>О чём родители должны заботиться в первые семь лет жизни ребёнка?</b>
        <br />
        Почему родители должны быть так внимательны к телесному созреванию детей? Что является действительной
        подготовкой к тому, чтобы ребёнок в 7 лет смог стать учеником?» Эта тема важна для всех родителей. Как
        показывает наш опыт, к сожалению, к 7 годам многие дети уже несут в себе немалое число проблем из-за того, что
        родители неправильно расставляли акценты в своём воспитании.
        <br />
        <br />
        <div className='text-sm'>Лекция 3 и 4</div>
        <b className='text-xl'>Становление ребёнка учеником во втором семилетии</b>
        <br />
        Что такое образное, художественное преподавание в вальдорфской школе? Как пробуждать у детей собственное
        представления? В чём значение кризиса 3-го класса (Первого Рубикона)? Как рождаются современные подростки? Как
        происходит переход «от чувств к мышлению»? Второй Рубикон. Рождение современных подростков.
        <br />
        <br />
        <div className='text-sm'>Лекция 5 и 6</div>
        <b className='text-xl'>Подростки между силами Эроса и Танатоса: Образование в третьем семилетии</b>
        <br />
        Здоровое пробуждение сил Эроса. Сексуальная зрелость. Пробуждение мужского и женского. Существенные различия в
        развитии девушек и юношей в этот период. Здоровое обращение с силами Танатоса. Пробуждение индивидуальности
        путём развития творческого мышления в отличие от рефлекторного мышления. Человеческая встреча мужского и
        женского.
      </>
    ),
    footer: footerZoomInfo,
    events: [
      {
        startTime: '2023-10-24 16:00:00',
        duration: 90,
        title: 'О чём родители должны заботиться в первые семь лет жизни ребёнка?',
        author: 'v.pegov',
      },
      {
        startTime: '2023-11-14 16:00:00',
        duration: 90,
        title: 'О чём родители должны заботиться в первые семь лет жизни ребёнка?',
        author: 'v.pegov',
      },
      {
        startTime: '2023-11-21 16:00:00',
        duration: 90,
        title: 'Становление ребёнка учеником во втором семилетии',
        author: 'v.pegov',
      },
      {
        startTime: '2023-12-05 16:00:00',
        duration: 90,
        title: 'Становление ребёнка учеником во втором семилетии',
        author: 'v.pegov',
      },
      {
        startTime: '2023-12-19 16:00:00',
        duration: 90,
        title: 'Подростки между силами Эроса и Танатоса: Образование в третьем семилетии',
        author: 'v.pegov',
      },
      {
        startTime: '2024-01-09 16:00:00',
        duration: 90,
        title: 'Подростки между силами Эроса и Танатоса: Образование в третьем семилетии',
        author: 'v.pegov',
      },
    ],
    costs: [
      justCostAndDescription('Стоимость всего цикла 6 лекций', 2400),
      justCostAndDescription('Стоимость одной лекции', 450),
    ],
  },
  {
    id: 7,
    status: 'planned',
    alert: <div className='text-left'>На первом занятии мы можем обсудить удобное время следующих дней.</div>,
    title: 'Семинар по изготовлению Рождественских фигур из шерсти',
    body: 'Уважаемые педагоги, родители! Дошкольный Вальдорфский центр приглашает Вас на семинар по изготовлению Рождественских фигур из шерсти (сухое и мокрое валяние)',
    description: (
      <>
        <b>Искусство правильного утомления, или как развлекательность убивает образование</b>
        <br />
        <br />
        Уважаемые педагоги, родители! Дошкольный Вальдорфский центр приглашает Вас на семинар по изготовлению
        Рождественских фигур из шерсти (сухое и мокрое валяние)
      </>
    ),
    footer: footerFrunzaInfo,
    events: [
      {
        startTime: '2023-12-03 18:00:00',
        duration: 180,
        title: 'Мария, Иосиф, младенец в яслях',
        author: 'e.gramotkina',
      },
      {
        startTime: '2023-12-04 18:00:00',
        duration: 180,
        title: 'Мария, Иосиф, младенец в яслях',
        author: 'e.gramotkina',
      },
      {
        startTime: '2023-12-05 18:00:00',
        duration: 180,
        title: 'Мария, Иосиф, младенец в яслях',
        author: 'e.gramotkina',
      },
    ],
    costs: [justCostAndDescription('Стоимость вместе с материалами', 4000)],
  },
  {
    id: 8,
    status: 'planned',
    alert: null,
    title: 'Семинар по изготовлению Рождественских фигур из шерсти',
    body: 'Уважаемые педагоги, родители! Дошкольный Вальдорфский центр приглашает Вас на семинар по изготовлению Рождественских фигур из шерсти (животные)',
    description: (
      <>
        <b>Искусство правильного утомления, или как развлекательность убивает образование</b>
        <br />
        <br />
        Уважаемые педагоги, родители! Дошкольный Вальдорфский центр приглашает Вас на семинар по изготовлению
        Рождественских фигур из шерсти (животные)
      </>
    ),
    footer: footerFrunzaInfo,
    events: [
      {
        startTime: '2023-12-12 18:00:00',
        duration: 180,
        title: 'Ослик и бычок',
        author: 'e.gramotkina',
      },
      {
        startTime: '2023-12-13 18:00:00',
        duration: 180,
        title: 'Ослик и бычок',
        author: 'e.gramotkina',
      },
      {
        startTime: '2023-12-14 18:00:00',
        duration: 180,
        title: 'Ослик и бычок',
        author: 'e.gramotkina',
      },
    ],
    costs: [justCostAndDescription('Стоимость вместе с материалами', 4000)],
  },
  {
    id: 9,
    status: 'planned',
    alert: null,
    title: 'Cеминар по послойной живописи акварелью',
    body: 'Дорогие друзья! Приглашаем вас на цикл семинаров по послойной живописи акварелью «Праздники года».',
    description: 'Вторая встреча состоится 16 и 17 декабря по адресу: Москва, Комсомольский просп. 38/16.',
    footer: footerNOvchinnikova,
    events: [
      {
        startTime: '2023-12-16 10:00:00',
        duration: 8 * 60,
        title: 'Праздники года',
        author: 'n.ovchinnikova',
      },
      {
        startTime: '2023-12-17 10:00:00',
        duration: 6 * 60,
        title: 'Праздники года',
        author: 'n.ovchinnikova',
      },
    ],
    costs: [justCostAndDescription('Стоимость', 4000)],
  },
  {
    id: 10,
    status: 'planned',
    alert: linkAndRequisitesAlertAndInfo(
      'https://docs.google.com/forms/d/e/1FAIpQLSfvwbtoJRkebzKznSneEgpWLMVow15yhlRGuv5AZ3sICAsH4w/viewform?usp=pp_url',
      'Ссылка на участие в лекции придёт 23 января 2024 в 15.30 после оплаты'
    ),
    title: 'Искусство правильного утомления или как развлекательность убивает образование',
    body: 'Дошкольный Вальдорфский центр приглашает Родителей, Педагогов и всех желающих на лекции Владимира Пегова',
    description: (
      <>
        <b>Искусство правильного утомления или как развлекательность убивает образование</b>
        <br />
        <br />
        Дошкольный вальдорфский центр приглашает педагогов, родителей и всех желающих на цикл онлайн лекций В. А. Пегова
        (ведущий преподаватель Периодического семинара, Дошкольного Вальдорфского центра. Кандидат педагогических наук,
        доцент. Доцент кафедры педагогики и психологии, основатель и учитель Смоленской вальдорфской школы).
        <br />
        «Современное воспитание в первые три семилетия: Педагогика достойная ребёнка» (цикл из шести лекций)
        <br />
        <br />
        <div className='text-sm'>Лекция 1 и 2</div>
        <b className='text-xl'>О чём родители должны заботиться в первые семь лет жизни ребёнка?</b>
        <br />
        Цивилизация потребительства агрессивно навязывает представление о человеке-потребителе. Соответственно,
        повсеместно внедряемая англо-американская модель образования формирует потребительство, как главную
        «способность» у детей. Потребительство, в свою очередь, предполагает стремление к постоянному комфорту. Отсюда
        «развлекательное образование» становится ведущим трендом во всём мире. Важно понять, что на самом деле
        развивается при этом у детей.
      </>
    ),
    footer: footerZoomInfo,
    events: [
      {
        startTime: '2024-01-23 16:00:00',
        duration: 90,
        title: 'Искусство правильного утомления или как развлекательность убивает образование',
        author: 'v.pegov',
      },
    ],
    costs: [justCostAndDescription('Стоимость лекции', 450)],
  },
  {
    id: 11,
    status: 'planned',
    alert: null,
    title: 'Второе семилетие: Формирование душевной конституции',
    body: 'Приглашаем родителей и всех желающих на лекторий «Как я могу понять своего ребенка. Второе семилетие: Формирование душевной конституции.»',
    description: (
      <>
        Приглашаем родителей и всех желающих на лекторий «Как я могу понять своего ребенка»
        <br />
        Лекции читает Владимир Пегов, ведущий преподаватель Периодического семинара, Дошкольного вальдорфского центра.
        Кандидат педагогических наук, профессор кафедры педагогики и психологии СГУС, основатель и учитель Смоленской
        вальдорфской школы.
        <br />
        Второе семилетие: Формирование душевной конституции
        <br />
        <div className='text-sm'>Лекция 1 и 2</div>
        <b className='text-xl'>
          Возраст 1-3-го классов. Пробуждение способности к созданию образов и представлений, к произвольной памяти.
          Кризис 9,5-10 лет (Первый рубикон)
        </b>
        <br />
        <div className='text-sm'>Лекция 3 и 4</div>
        <b className='text-xl'>
          Возраст 4-6-го классов. Пробуждение способности к формированию внутренних чувств. Кризис 12 лет (Второй
          рубикон)
        </b>
        <br />
        <div className='text-sm'>Лекция 5 и 6</div>
        <b className='text-xl'>
          Возраст 6-8-го классов. Пик подростковости. Пробуждение способности к самостоятельному,
          причинно-следственному, логическому мышлению
        </b>
      </>
    ),
    footer: footerFrunzaInfo,
    events: [
      {
        startTime: '2024-01-29 18:30:00',
        duration: 75,
        title: 'Возраст 1-3-го классов. Лекция 1.',
        author: 'v.pegov',
      },
      {
        startTime: '2024-01-29 20:00:00',
        duration: 75,
        title: 'Возраст 1-3-го классов. Лекция 2.',
        author: 'v.pegov',
      },
      {
        startTime: '2024-01-30 18:30:00',
        duration: 75,
        title: 'Возраст 4-6-го классов. Лекция 1.',
        author: 'v.pegov',
      },
      {
        startTime: '2024-01-30 20:00:00',
        duration: 75,
        title: 'Возраст 4-6-го классов. Лекция 2.',
        author: 'v.pegov',
      },
      {
        startTime: '2024-01-31 18:30:00',
        duration: 75,
        title: 'Возраст 6-8-го классов. Лекция 1.',
        author: 'v.pegov',
      },
      {
        startTime: '2024-01-31 20:00:00',
        duration: 75,
        title: 'Возраст 6-8-го классов. Лекция 2.',
        author: 'v.pegov',
      },
    ],
    costs: [
      justCostAndDescription('Стоимость курса «Второе семилетие»', 2700),
      justCostAndDescription('Стоимость одного дня', 1000),
    ],
  },
  {
    id: 12,
    status: 'planned',
    alert: null,
    title: 'Третье семилетие: подростки между силами Эроса и Танатоса',
    body: 'Приглашаем родителей и всех желающих на лекторий «Как я могу понять своего ребенка. Третье семилетие: подростки между силами Эроса и Танатоса.»',
    description: (
      <>
        Приглашаем родителей и всех желающих на лекторий «Как я могу понять своего ребенка»
        <br />
        Лекции читает Владимир Пегов, ведущий преподаватель Периодического семинара, Дошкольного вальдорфского центра.
        Кандидат педагогических наук, профессор кафедры педагогики и психологии СГУС, основатель и учитель Смоленской
        вальдорфской школы.
        <br />
        Третье семилетие: подростки между силами Эроса и Танатоса
        <br />
        <div className='text-sm'>Лекция 1 и 2</div>
        <b className='text-xl'>
          Земная зрелость. Пубертат и юность - в чём разница? Телесная, душевная и духовная зрелость. Культурное
          рождение и становление данных феноменов возраста в конце XIX-XX вв. Кто они современные подростки, тинейджеры,
          юноши и девушки? Как найти правильную тональность в жизни и в воспитании подростков и юношей?
        </b>
        <br />
        <div className='text-sm'>Лекция 3 и 4</div>
        <b className='text-xl'>
          Здоровое пробуждение сил Эроса. Сексуальная зрелость. Пробуждение мужского и женского. Существенные различия в
          развитии девушек и юношей в этот период.
        </b>
        <br />
        <div className='text-sm'>Лекция 5 и 6</div>
        <b className='text-xl'>
          Здоровое обращение с силами Танатоса. Пробуждение индивидуальности путём развития творческого мышления в
          отличие от рефлекторного мышления. Человеческая встреча мужского и женского.
        </b>
      </>
    ),
    footer: footerFrunzaInfo,
    events: [
      {
        startTime: '2024-02-05 18:30:00',
        duration: 75,
        title: 'Земная зрелость. Пубертат и юность - в чём разница? Лекция 1.',
        author: 'v.pegov',
      },
      {
        startTime: '2024-02-05 20:00:00',
        duration: 75,
        title: 'Земная зрелость. Пубертат и юность - в чём разница? Лекция 2.',
        author: 'v.pegov',
      },
      {
        startTime: '2024-02-06 18:30:00',
        duration: 75,
        title: 'Здоровое пробуждение сил Эроса. Сексуальная зрелость. Лекция 1.',
        author: 'v.pegov',
      },
      {
        startTime: '2024-02-06 20:00:00',
        duration: 75,
        title: 'Здоровое пробуждение сил Эроса. Сексуальная зрелость. Лекция 2.',
        author: 'v.pegov',
      },
      {
        startTime: '2024-02-07 18:30:00',
        duration: 75,
        title: 'Здоровое обращение с силами Танатоса. Пробуждение индивидуальности. Лекция 1.',
        author: 'v.pegov',
      },
      {
        startTime: '2024-02-07 20:00:00',
        duration: 75,
        title: 'Здоровое обращение с силами Танатоса. Пробуждение индивидуальности. Лекция 2.',
        author: 'v.pegov',
      },
    ],
    costs: [
      justCostAndDescription('Стоимость курса Третье семилетие»', 2700),
      justCostAndDescription('Стоимость одного дня', 1000),
    ],
  },
  {
    id: 13,
    status: 'planned',
    alert: null,
    title: 'Человек и его кризисы в середине жизни (21 - 42 года)',
    body: 'Дошкольный Вальдорфский центр приглашает Педагогов, Родителей и всех желающих на цикл лекций',
    description: (
      <>
        Человек и его кризисы в середине жизни (21 - 42 года)
        <br />
        <br />
        Лекция 1: Индивидуализация души в середине жизни
        <br />
        Я сотворил, говорит Господь, прекрасное тело; даю тебе власть сделать нечто лучшее: создай прекрасную душу»
        Иоанн Златоуст. Мы много говорим о возрастных законах развития детей. Но иногда случается поговорить и о
        возрасте и кризисах у взрослых людей. О том возрасте, в котором, как правило, взрослые люди являются
        воспитателями детей. Поэтому так важно разобраться в себе, чтобы лучше понимать, что мы делаем с детьми.
        <br />
        <br />
        Лекция 2: Душа в своём рациональном и самосознающем качестве
        <br />
        «Современный человек - или, повторим это вновь, человек непосредственного настоящего - встречается достаточно
        редко, ибо он должен быть в высшей степени сознательным» К.Г. Юнг. Стоимость - 1000 рублей
      </>
    ),
    footer: footerFrunzaInfo,
    events: [
      {
        startTime: '2024-02-01 18:30:00',
        duration: 90,
        title: 'Индивидуализация души в середине жизни',
        author: 'v.pegov',
      },
      {
        startTime: '2024-02-02 18:30:00',
        duration: 90,
        title: 'Душа в своём рациональном и самосознающем качестве',
        author: 'v.pegov',
      },
    ],
    costs: [justCostAndDescription('Стоимость двух лекций', 1000)],
  },
  {
    id: 14,
    status: 'planned',
    alert: null,
    title: 'Как распознать темперамент у своего ребёнка и какие у нас есть возможности для его гармонизации',
    body: 'Дошкольный Вальдорфский центр приглашает Педагогов, Родителей и всех желающих на цикл лекций',
    description: (
      <>
        Как распознать темперамент у своего ребёнка и какие у нас есть возможности для его гармонизации
        <br />
        Рассмотрение темпераментов через призму четырёх стихий. Гармоничные и дисгармоничные темпераменты. Как можно
        гармонизировать темпераменты у детей с помощью питания, цвета, музыки и проч.
      </>
    ),
    footer: footerFrunzaInfo,
    events: [
      {
        startTime: '2024-02-03 10:00:00',
        duration: 80,
        title: 'Как распознать темперамент у своего ребёнка и какие у нас есть возможности для его гармонизации',
        author: 'v.pegov',
      },
      {
        startTime: '2024-02-03 11:40:00',
        duration: 80,
        title: 'Как распознать темперамент у своего ребёнка и какие у нас есть возможности для его гармонизации',
        author: 'v.pegov',
      },
    ],
    costs: [justCostAndDescription('Стоимость двух лекций', 1000)],
  },
  {
    id: 15,
    status: 'planned',
    alert: null,
    title: 'Современные подростки: между свободой и зависимостями',
    body: 'Дошкольный Вальдорфский центр приглашает Педагогов, Родителей и всех желающих на цикл лекций',
    description: (
      <>
        Современные подростки: между свободой и зависимостями
        <br />
        Современные подростки сейчас стоят между жизненно важным выбором: свобода или многочисленные виды зависимостей?
        В чём разница между «старыми» вещественными видами зависимостей (алкогольная, никотиновая, наркотическая) и
        «новыми» невещественными (так называемыми «технологическими»)? Какой опыт может помочь подросткам быть
        устойчивыми к любым зависимостям?
      </>
    ),
    footer: footerFrunzaInfo,
    events: [
      {
        startTime: '2024-02-08 18:30:00',
        duration: 75,
        title: 'Современные подростки: между свободой и зависимостями',
        author: 'v.pegov',
      },
      {
        startTime: '2024-02-08 20:00:00',
        duration: 75,
        title: 'Современные подростки: между свободой и зависимостями',
        author: 'v.pegov',
      },
    ],
    costs: [justCostAndDescription('Стоимость двух лекций', 1000)],
  },
  {
    id: 16,
    status: 'planned',
    alert: linkAndRequisitesAlertAndInfo(
      'https://docs.google.com/forms/d/e/1FAIpQLScBcZ2RN5pIFci-4-IMqZujcn03tmNQsAL7-LU_TYPXezw0HQ/viewform?usp=pp_url',
      <>
        Можно оплатить по реквизитам. Большая просьба производите оплату заранее. Произведенная оплата в день проведения
        лекции поступает на счет только на следующий день. Спасибо за понимание. В назначении платежа «Оплата
        образовательных услуг»
        <br />
        Ссылку на участие в лекции пришлю 13 февраля 2024 в 15.30 после оплаты и поступления денег на счет организации.
        <br />
      </>
    ),
    title: '«Безграничные дети» и проблемы «свободного воспитания». Границы, авторитет и свобода»',
    body: 'Дошкольный Вальдорфский центр приглашает Педагогов, Родителей и всех желающих на лекции В. Пегова',
    description: (
      <>
        «Безграничные дети» и проблемы «свободного воспитания». Границы, авторитет и свобода»
        <br />
        Почему у нынешних детей возникают трудности с правильным переживанием границ: телесных и социальных? Почему
        взрослые люди – родители, воспитатели и учителя – сейчас не авторитетны для детей? Каким образом современным
        подросткам разрешить вопрос соотнесённости ограниченности и свободы?
      </>
    ),
    footer: footerZoomInfo,
    events: [
      {
        startTime: '2024-02-13 16:00:00',
        duration: 90,
        title: '«Безграничные дети» и проблемы «свободного воспитания». Границы, авторитет и свобода»',
        author: 'v.pegov',
      },
      {
        startTime: '2024-02-20 16:00:00',
        duration: 90,
        title: '«Безграничные дети» и проблемы «свободного воспитания». Границы, авторитет и свобода»',
        author: 'v.pegov',
      },
    ],
    costs: [
      titleAndDescription('Стоимость двух лекций', 'При оплате до 12 февраля', 900),
      titleAndDescription('Стоимость двух лекций', 'При оплате c 13 февраля', 1000),
    ],
  },
  {
    id: 17,
    status: 'planned',
    alert: (
      <div className='text-left'>
        <div>
          Регистрация на лекции по{' '}
          <a
            className='text-orange-300'
            href='https://docs.google.com/forms/d/e/1FAIpQLSc671FlZVCLbvmtjeLhfr3oNC5nlAyXKg6Ds09hZGG3L5FVCg/viewform?usp=pp_url'
          >
            ссылке
          </a>
        </div>
        <div>
          Время занятий с 14:00 до 19:30 ежедневно. В это время будут проходить собеседования для желающих поступить на
          3-х годичное обучение. Питание и проживание самостоятельно.
        </div>
      </div>
    ),
    title: 'Педагогические задачи в дошкольном возрасте',
    body: 'Коллегия Дошкольный Вальдорфского центра приглашает Вас на ознакомительный семинар «Педагогические задачи в дошкольном возрасте»',
    description: (
      <>
        Коллегия Дошкольный Вальдорфского центра приглашает Вас на ознакомительный семинар, который состоится с 18 - 22
        марта 2024 г. Тема семинара «Педагогические задачи в дошкольном возрасте». Лектор Кира Бабич (преподаватель
        Дошкольного Вальдорфского центра, вальдорфский воспитатель с 22-х летним опытом работы.)
      </>
    ),
    footer: footerFrunzaInfo,
    events: [
      {
        startTime: '2024-03-18 14:00:00',
        duration: 90,
        title: 'Лекции «Педагогические задачи в дошкольном возрасте»',
        author: 'k.babich',
      },
      {
        startTime: '2024-03-18 16:00:00',
        duration: 90,
        title: 'Рукоделие',
        author: 'empty',
      },
      {
        startTime: '2024-03-18 18:00:00',
        duration: 90,
        title: 'Пение',
        author: 'empty',
      },
      {
        startTime: '2024-03-19 14:00:00',
        duration: 90,
        title: 'Лекции «Педагогические задачи в дошкольном возрасте»',
        author: 'k.babich',
      },
      {
        startTime: '2024-03-19 16:00:00',
        duration: 90,
        title: 'Рукоделие',
        author: 'empty',
      },
      {
        startTime: '2024-03-19 18:00:00',
        duration: 90,
        title: 'Пение',
        author: 'empty',
      },
      {
        startTime: '2024-03-20 14:00:00',
        duration: 90,
        title: 'Лекции «Педагогические задачи в дошкольном возрасте»',
        author: 'k.babich',
      },
      {
        startTime: '2024-03-20 16:00:00',
        duration: 90,
        title: 'Рукоделие',
        author: 'empty',
      },
      {
        startTime: '2024-03-20 18:00:00',
        duration: 90,
        title: 'Пение',
        author: 'empty',
      },
      {
        startTime: '2024-03-21 14:00:00',
        duration: 90,
        title: 'Лекции «Педагогические задачи в дошкольном возрасте»',
        author: 'k.babich',
      },
      {
        startTime: '2024-03-21 16:00:00',
        duration: 90,
        title: 'Рукоделие',
        author: 'empty',
      },
      {
        startTime: '2024-03-21 18:00:00',
        duration: 90,
        title: 'Пение',
        author: 'empty',
      },
      {
        startTime: '2024-03-22 14:00:00',
        duration: 90,
        title: 'Лекции «Педагогические задачи в дошкольном возрасте»',
        author: 'k.babich',
      },
      {
        startTime: '2024-03-22 16:00:00',
        duration: 90,
        title: 'Рукоделие',
        author: 'empty',
      },
      {
        startTime: '2024-03-22 18:00:00',
        duration: 90,
        title: 'Пение',
        author: 'empty',
      },
    ],
    costs: [justCostAndDescription('Стоимость всего семинара', 11000)],
  },
  {
    id: 18,
    status: 'planned',
    alert: linkAndRequisitesAlertWithQr(
      'https://docs.google.com/forms/d/e/1FAIpQLSf5ZgxyBhNA2plexH2zF-y378nGEMlY_gRjtFtN3KoYUWxvhw/viewform?usp=pp_url',
      18
    ),
    title: 'Дети и спорт: телесные, душевные и духовные последствия занятий спортом',
    body: 'Дошкольный Вальдорфский центр приглашает Педагогов, Родителей и всех желающих на цикл лекций',
    description: (
      <>
        Дети и спорт: телесные, душевные и духовные последствия занятий спортом
        <br />
        Является ли спорт здоровой альтернативой гиподинамии (обездвиженности детей)? Что происходит с телом ребёнка при
        занятиях спортом? Какие душевные качества формируются спортом? Какого рода духовность культивируется при
        спортивных занятиях?
      </>
    ),
    footer: footerZoomInfo,
    events: [
      {
        startTime: '2024-03-05 16:00:00',
        duration: 90,
        title: 'Дети и спорт: телесные, душевные и духовные последствия занятий спортом',
        author: 'v.pegov',
      },
      {
        startTime: '2024-03-19 16:00:00',
        duration: 90,
        title: 'Дети и спорт: телесные, душевные и духовные последствия занятий спортом',
        author: 'v.pegov',
      },
    ],
    costs: [
      titleAndDescription('Стоимость двух лекций', 'При оплате до 3 марта', 900),
      titleAndDescription('Стоимость двух лекций', 'При оплате c 4 марта', 1000),
    ],
  },
  {
    id: 19,
    status: 'planned',
    alert: (
      <div className='text-left'>
        Запись по телефону: <Phone phoneNumber='+7 (920) 665-90-43' /> - Ирина Каткова
        <br />
        или e-mail: <Email />
      </div>
    ),
    title: 'Дети и спорт: телесные, душевные и духовные последствия занятий спортом',
    body: 'Дошкольный Вальдорфский центр приглашает Педагогов, Родителей и всех желающих на цикл лекций',
    description: (
      <>
        Весна, время, когда природа оживает и наши тела требуют восстановления после зимних месяцев. Весенний упадок сил
        (нехватка энергии, вялость, общая ослабленность), естественное состояние, которое затрагивает многих
        людей.Весна, лучшее время для перемен!
        <br />
        Приглашаем освоить метод ритмического втирания, с помощью которого вы сможете позаботиться о себе и своих
        близких, наполниться жизненной энергией. Использование натуральных масел позволит напитать, увлажнить кожу после
        зимы. Ритмическое втирание - это лечебный массаж с нанесением различных субстанций. При минимальном воздействии
        с минимальным количеством лечебной субстанции достигается максимальный эффект. Это становится возможным
        благодаря специальным дышащим, оживляющим движениям.
        <br />В программе:
        <ul className='list-disc'>
          <li>Философия метода ритм втирания</li>
          <li>Упражнения с водой, постановка рук</li>
          <li>Втирание ноги</li>
          <li>Втирание печени</li>
        </ul>
      </>
    ),
    footer: footerFrunzaInfoWithoutPhoneAndEmail,
    events: [
      {
        startTime: '2024-03-11 18:00:00',
        duration: 180,
        title: 'Метод ритмического втирания',
        author: 'i.katkova',
      },
      {
        startTime: '2024-03-12 18:00:00',
        duration: 180,
        title: 'Метод ритмического втирания',
        author: 'i.katkova',
      },
      {
        startTime: '2024-03-13 18:00:00',
        duration: 180,
        title: 'Метод ритмического втирания',
        author: 'i.katkova',
      },
    ],
    costs: [justCostAndDescription('Стоимость участия', 6000)],
  },
  {
    id: 20,
    status: 'planned',
    alert: null,
    title: 'Жестовые игры Вильмы Эллерзик',
    body: 'Приглашаем вас на субботний семинар по жестовым играм Вильмы Эллерзик.',
    description: <></>,
    footer: footerFrunzaInfo,
    events: [
      {
        startTime: '2024-04-06 09:00:00',
        duration: 210,
        title: 'Семинар по жестовым играм Вильмы Эллерзик',
        author: 'k.babich',
      },
    ],
    costs: [],
  },
  {
    id: 21,
    status: 'planned',
    alert: linkAndRequisitesAlertWithQr(
      'https://docs.google.com/forms/d/e/1FAIpQLSf6Dei-zEUV4i9tBTeX-bBF7z114mtKJoq0EcUEud4Ryr7DGA/viewform?usp=pp_url',
      21
    ),
    title: 'Мотивы и интересы в развитии ребёнка',
    body: 'Дошкольный Вальдорфский центр приглашает Педагогов, Родителей и всех желающих на лекции',
    description: (
      <>
        Мотивы и интересы в развитии ребёнка
        <br />
        Что такое человеческий мотив и интерес? Почему сейчас всё чаще говорят о «мотивировании» детей, подростков и
        взрослых людей? Какова роль образования в пробуждении собственных мотивов и интересов?
      </>
    ),
    footer: footerZoomInfo,
    events: [
      {
        startTime: '2024-04-16 16:00:00',
        duration: 90,
        title: 'Мотивы и интересы в развитии ребёнка',
        author: 'v.pegov',
      },
      {
        startTime: '2024-04-23 16:00:00',
        duration: 90,
        title: 'Мотивы и интересы в развитии ребёнка',
        author: 'v.pegov',
      },
    ],
    costs: [
      titleAndDescription('Стоимость двух лекций', 'При оплате до 14 апреля', 900),
      titleAndDescription('Стоимость двух лекций', 'При оплате c 15 апреля', 1000),
    ],
  },
  {
    id: 22,
    status: 'planned',
    alert: linkAndRequisitesAlertWithQr(
      'https://docs.google.com/forms/d/e/1FAIpQLSfxPHFl7hmP6c1sFHaJed7e5UOZtCxjHWtWhw0yX6PoQafXgw/viewform?usp=pp_url',
      22
    ),
    title: 'Значение сказок для здорового развития ребёнка и «мультики» как их антипод',
    body: 'Дошкольный Вальдорфский центр приглашает Педагогов, Родителей и всех желающих на лекции',
    description: (
      <>
        Значение сказок для здорового развития ребёнка и «мультики» как их антипод
        <br />
        Какие глубинные смыслы несут в себе народные сказки для души ребёнка. Живые и истинные образы сказок и их
        мультяшные противообразы (смысловые перевёртыши).
      </>
    ),
    footer: footerZoomInfo,
    events: [
      {
        startTime: '2024-05-14 16:00:00',
        duration: 90,
        title: 'Значение сказок для здорового развития ребёнка и «мультики» как их антипод',
        author: 'v.pegov',
      },
      {
        startTime: '2024-05-21 16:00:00',
        duration: 90,
        title: 'Значение сказок для здорового развития ребёнка и «мультики» как их антипод',
        author: 'v.pegov',
      },
    ],
    costs: [
      titleAndDescription('Стоимость двух лекций', 'При оплате до 11 мая', 900),
      titleAndDescription('Стоимость двух лекций', 'При оплате c 12 мая', 1000),
    ],
  },
  {
    id: 23,
    status: 'planned',
    alert: null,
    title: 'Лекции Гунтера Гербхарда',
    body: 'Дорогие воспитатели, учителя и родители! Коллегия Дошкольного Вальдорфского центра приглашает Вас на лекции Гунтера Гербхарда (доктор естественных наук, более 15 лет работал учителем естествознания в вальдорфских школах Германии, преподаватель вальдорфских семинаров в России и за рубежом)',
    description:
      'Будущим вальдорфским педагогам в первой вальдорфской школе Рудольф Штайнер дал задание: «Научить дышать...» В нашем мире, который стал очень скудным на дыхание, а иногда даже астматическим, мне кажется важным обратить на это внимание.',
    footer: footerFrunzaInfo,
    events: [
      {
        startTime: '2024-05-28 18:30:00',
        duration: 90,
        title: 'Что означает дыхание? Это просто вдох, чтобы остаться в живых?',
        author: 'g.gunter',
      },
      {
        startTime: '2024-05-30 18:30:00',
        duration: 90,
        title: 'Что означает дыхание? Это просто вдох, чтобы остаться в живых?',
        author: 'g.gunter',
      },
    ],
    costs: [justCostAndDescription('Стоимость двух лекций', 1000)],
  },
  {
    id: 24,
    status: 'planned',
    alert: null,
    title: 'Лекции Гунтера Гербхарда',
    body: 'Дорогие воспитатели, учителя и родители! Коллегия Дошкольного Вальдорфского центра приглашает Вас на лекции Гунтера Гербхарда (доктор естественных наук, более 15 лет работал учителем естествознания в вальдорфских школах Германии, преподаватель вальдорфских семинаров в России и за рубежом)',
    description:
      'Будущим вальдорфским педагогам в первой вальдорфской школе Рудольф Штайнер дал задание: «Научить дышать...» В нашем мире, который стал очень скудным на дыхание, а иногда даже астматическим, мне кажется важным обратить на это внимание.',
    footer: footerFrunzaInfo,
    events: [
      {
        startTime: '2024-06-04 18:30:00',
        duration: 90,
        title: 'Что такое кровь и сердце в социальном организме?',
        author: 'g.gunter',
      },
    ],
    costs: [justCostAndDescription('Стоимость лекции', 500)],
  },
  {
    id: 25,
    status: 'planned',
    alert: linkAndRequisitesAlertWithQr(
      'https://docs.google.com/forms/d/e/1FAIpQLSdozeQIAitEG0fNIDJNCUJj_h2uFj6kQN9XeNDS2GHtX-iZZg/viewform?usp=pp_url',
      25
    ),
    title: 'Атака на детство: как угнетаются три ключевые человеческие способности – прямохождение, речь и мышление',
    body: 'Дошкольный Вальдорфский центр приглашает Педагогов, Родителей и всех желающих на цикл лекций',
    description: (
      <>
        Атака на детство: как угнетаются три ключевые человеческие способности – прямохождение, речь и мышление
        <br />
        <br />
        Атака на прямохождение (ходунки, помочи, ортопедическая обувь, и проч.).
        <br />
        Атака на речь («немые» родители, «шумящий мир», гаджеты, и проч.).
        <br />
        Атака на мышление (школы раннего развития, хаос информации, и проч.).
      </>
    ),
    footer: footerZoomInfo,
    events: [
      {
        startTime: '2024-06-04 16:00:00',
        duration: 90,
        title:
          'Атака на детство: как угнетаются три ключевые человеческие способности – прямохождение, речь и мышление',
        author: 'v.pegov',
      },
      {
        startTime: '2024-06-25 16:00:00',
        duration: 90,
        title:
          'Атака на детство: как угнетаются три ключевые человеческие способности – прямохождение, речь и мышление',
        author: 'v.pegov',
      },
    ],
    costs: [
      titleAndDescription('Стоимость двух лекций', 'При оплате до 31 мая', 900),
      titleAndDescription('Стоимость двух лекций', 'При оплате c 1 июня', 1000),
    ],
  },
]
